.login-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-box {
    min-width: 450px;
    background-color: rgba(0,0,0,.08);
    border: 1px solid rgba(0,0,0,.06);
    padding: 4rem 2rem;
}

.lg-chose-lang {
    display: flex;
    justify-content: flex-end;
}

.lang-img {
    height: 20px;
    padding-right: 5px;
    margin-bottom: 4px;
}

.sl-lang {
    width: 125px;
}

.lg-title {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
}

.lg-content {
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.lg-btn {
    padding-top: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 !important;
}

.text-a {
    color: rgb(24, 144, 255);
    font-size: 14px !important;
    cursor: pointer;
}