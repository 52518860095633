.modal-all .modal-dialog {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 35%;
}

.modal-all .modal-dialog .modal-header {
    border-bottom: 0;
}

.modal-all .modal-dialog .modal-body {
    text-align: center;
    font-weight: 400;
}

.modal-all .modal-dialog .modal-footer {
    justify-content: center;
    border-top: 0;
}